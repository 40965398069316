import Help from "./pages/help/Help";
import Algorithm from "./pages/help/faq/Algorithm";
import ExportingSolution from "./pages/help/faq/ExportingSolution";
import MyOwnModel from "./pages/help/faq/MyOwnModel";
import NewProject from "./pages/help/faq/NewProject";
import SimulationCost from "./pages/help/faq/SimulationCost";
import Energy from "./pages/help/general_documentation/Energy";
import Finite from "./pages/help/general_documentation/Finite_elements";
import Results from "./pages/help/general_documentation/Results";
import VQA from "./pages/help/general_documentation/VQA";
import ContactForm from "./pages/help/support/ContactForm";
import ReportBug from "./pages/help/support/ReportBug";
import Elastic2D from "./pages/help/tutorials/Elastic2D";
import Hypoelastic1D from "./pages/help/tutorials/Hypoelastic1D";
import Others from "./pages/help/tutorials/Others";
import Jobs from "./pages/jobs/Jobs";
import Landing, { use_cases } from "./pages/landing/Landing";
import LoginPage from "./pages/login";
import Profile from "./pages/profile/Profile";

type Routes = {
  path: string;
  element: () => JSX.Element;
  title: string;
  linkName?: string;
};

export const routes: Routes[] = [
  {
    path: "/login",
    element: LoginPage,
    title: "Login page",
  },
  {
    path: "/",
    element: Landing,
    title: "Use case choice",
  },
  {
    path: "/profile",
    element: Profile,
    linkName: "Profile",
    title: "Profile Route",
  },
  {
    path: "/jobs",
    element: () => Jobs({}),
    linkName: "Jobs",
    title: "Jobs list",
  },
  {
    path: "/help",
    element: Help,
    linkName: "Help",
    title: "Help",
  },
  {
    path: "/help/general_documentation/finite_elements_method",
    element: Finite,
    linkName: "Finite elements method",
    title: "Finite",
  },
  {
    path: "/help/general_documentation/results",
    element: Results,
    linkName: "Results handling",
    title: "Results",
  },
  {
    path: "/help/general_documentation/energy",
    element: Energy,
    linkName: "Energy estimation",
    title: "Energy",
  },
  {
    path: "/help/general_documentation/VQA",
    element: VQA,
    linkName: "Variational Quantum Algorithms",
    title: "VQA",
  },
  {
    path: "/help/tutorials/hypoelastic1D",
    element: Hypoelastic1D,
    linkName: "Hypoelastic 1D deformation",
    title: "Hypoelastic1D",
  },
  {
    path: "/help/tutorials/elastic2D",
    element: Elastic2D,
    linkName: "Elastic 2D deformation",
    title: "Elastic2D",
  },
  {
    path: "/help/tutorials/Others",
    element: Others,
    linkName: "Others",
    title: "VQA",
  },
  {
    path: "/help/FAQ/new_project",
    element: NewProject,
    linkName: "How do I create a new project?",
    title: "NewProject",
  },
  {
    path: "/help/tutorials/exporting_solution",
    element: ExportingSolution,
    linkName: "Can I export the solution?",
    title: "ExportingSolution",
  },
  {
    path: "/help/tutorials/my_own_model",
    element: MyOwnModel,
    linkName: "Can I import my own model?",
    title: "MyOwnModel",
  },
  {
    path: "/help/tutorials/algorithm",
    element: Algorithm,
    linkName: "Can I choose the algorithm to use?",
    title: "Algorithm",
  },
  {
    path: "/help/tutorials/contact_form",
    element: ContactForm,
    linkName: "Contact form",
    title: "ContactForm",
  },
  {
    path: "/help/tutorials/report_a_bug",
    element: ReportBug,
    linkName: "Report a bug",
    title: "ReportBug",
  },
  {
    path: "/help/tutorials/simulation_cost",
    element: SimulationCost,
    linkName: "Can I estimate the cost of my simulation in advance?",
    title: "SimulationCost",
  },
];
// TODO: The routes for the tutorials and co could be dynamically generated
for (let use_case in use_cases) {
  routes.push({
    path: "/" + use_case.toLowerCase().replace(" ", "_"),
    element: use_cases[use_case].element,
    title: use_cases[use_case].page_title,
  });
  routes.push({
    path: "/" + use_case.toLowerCase().replace(" ", "_") + "/:state",
    element: use_cases[use_case].element,
    title: use_cases[use_case].page_title,
  });
}
