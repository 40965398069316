import styled from "styled-components";
import { getHostName } from "../../utils";
import default_image from "./icons8-image.svg";

const StyledCard = styled.a`
  height: 240px;
  width: 320px;
  text-align: center;
  transition: all 0.3s ease;
  display: inline-block;
  text-decoration: none;
  border-radius: 8px;
  overflow: hidden;
  background: radial-gradient(
    circle at 50% 50%,
    rgb(43 62 133) 20%,
    rgb(20 34 85) 90%
  );
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  cursor: pointer;

  :hover {
    border-radius: 16px;
    transform: translateY(-10px);
    box-shadow: 10px 20px 15px rgba(0, 0, 0, 0.45);
    filter: brightness(1.1);
  }
`;

const StyledUCImage = styled.img`
  width: 100%;
  height: 75%;
  object-fit: cover;
  border-bottom: 1px solidrgb(0, 0, 0);
  transition: filter 0.3s ease;

  ${StyledCard}:hover & {
  }
`;

const StyledUCName = styled.h4`
  margin: 16px auto;
  color: rgb(186, 207, 250);
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: color 0.3s ease;
  filter: drop-shadow(0.5px 0.5px 1px black);

  ${StyledCard}:hover & {
    color: rgb(255, 255, 255);
  }
`;
/**
 *
 * @category Components
 * This function generates the use-case card that appears in the homepage of the website.
 * @param props
 * @param props.title title is a 'string' containing the title of the use case associated with the card.
 * @param props.image_source image_source is a 'string' containing the file name of the use-case representative image.
 *
 */
export default function UseCaseCard({
  title,
  image_source,
}: {
  title: string;
  image_source?: string;
}) {
  const page_name = title.toLowerCase().replace(" ", "_");
  const link = getHostName() + "/" + page_name;

  return (
    <StyledCard href={link} key={"use_case_key_" + page_name}>
      <StyledUCImage
        src={image_source ? image_source : default_image}
        alt={title + " illustration"}
      />
      <StyledUCName className="use-case-name">{title}</StyledUCName>
    </StyledCard>
  );
}
