import styled from "styled-components";

/* SlideCheckbox Example:
<Input.SlideCheckboxWrapper>
    <Input.SlideCInput type="checkbox" className="tgl tgl-ios" name="mixing" id="counterflow" />
    <label className="tgl-btn" htmlFor="counterflow"></label>
    <Input.SlideCLabel htmlFor="counterflow">Counterflow</Input.SlideCLabel>
</Input.SlideCheckboxWrapper> 
*/

export const SlideCInput = styled.input`
  display: none;
`;

export const SlideLabelInput = styled.label`
  background-color: rgb(0, 174, 255);
  &:hover {
    background: var(--hover-background-color, #fff);
    border-color: var(--hover-border-color, #aaa);
    box-shadow: 11px 4px 6px rgb(0, 174, 255);
  }
`;

export const SlideCheckboxWrapper = styled.div`
  --blue: #0d7eff;
  --g08: #e1e5eb;
  --g04: #848ea1;
  display: flex;
  margin: 10px 0;

  .tgl,
  .tgl:after,
  .tgl:before,
  .tgl *:after,
  .tgl *:before,
  .tgl + .tgl-btn {
    box-sizing: border-box;
  }

  .tgl + .tgl-btn {
    display: block;
    width: 50px;
    height: 20px;
    background: var(--g08);
    border-radius: 20rem;
    padding: 0px;
    cursor: pointer;
    position: relative;
    user-select: none;
    transition: all 0.4s ease;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    &:hover {
      background: var(--hover-background-color, #fff);
      border-color: var(--hover-border-color, #aaa);
      box-shadow: 2px 2px 6px rgb(0, 174, 255);
    }
  }

  .tgl + .tgl-btn:after {
    content: "";
    position: absolute;
    width: 44%;
    height: 100%;
    left: 0px;
    background: #fff;
    border-radius: 2em;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: left 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .tgl + .tgl-btn:before {
    content: "No";
    position: absolute;
    top: 2px;
    left: 25px;
    font-size: 13px;
    color: var(--g04);
    transition: left 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .tgl:checked + .tgl-btn {
    background: var(--blue);
  }

  .tgl:checked + .tgl-btn:after {
    left: 56.5%;
  }

  .tgl:checked + .tgl-btn:before {
    content: "Yes";
    left: 7px;
    color: #fff;
  }
`;

export const SlideCLabel = styled.label`
  cursor: pointer;
  margin-left: 5px;
  width: max-content;
`;

export const CheckboxWrapper = styled.div`
  --size: 17px;
  position: relative;
  text-align: left;
  margin: 10px 0;
`;

export const CHiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &:checked + label::after {
    background-color: rgba(0, 127, 211, 0);
    border-color: rgba(255, 255, 255, 0);
  }

  &:checked + label:hover::after {
    box-shadow: 0 0 0px 0px rgba(0, 144, 211, 0);
  }

  &:checked + label::before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 2px;
    left: 6px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(40deg);
    animation: draw-checkmark ease 0.05s;
  }

  &:checked + label:hover::before {
    border: solid rgb(0, 166, 243);
    border-width: 0 2px 2px 0;
  }
`;

export const CLabel = styled.label`
  color: rgb(255, 255, 255);
  line-height: var(--size);
  cursor: pointer;
  position: relative;
  padding-left: calc(var(--size) + 13px);

  &:after {
    content: "";
    height: var(--size);
    width: var(--size);
    position: absolute;
    left: 0;
    top: 0;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 3px;
    transition: background-color ease 0.2s, border-color ease 0.2s;
  }

  &:hover::after {
    border: 2px solid rgb(0, 144, 211);
  }
`;

export const CGlobalStyles = styled.div`
  @keyframes draw-checkmark {
    from {
      transform: rotate(80deg);
    }
    to {
      transform: rotate(45deg);
    }
  }
`;
