import { MathJaxContext } from "better-react-mathjax";
import { useEffect, useState } from "react";
import { BiFileBlank, BiPlay, BiSave, BiUpload } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { CFDApi } from "../../../api";
import Header from "../../../components/header/Header";
import JobProgressBar from "../../../components/JobProgressBar";
import { Plot3D } from "../../../components/plot";
import Selector from "../../../components/Selector";
import UploadBox from "../../../components/UploadComponents";
import * as Input from "../../../components/use_case/input";
import * as Project from "../../../components/use_case/project";
import { FAST } from "../../../fast-solve";
import * as empty from "./emptyInitializers";
import * as Styled from "./style";

const e = (s: TemplateStringsArray) => "\\(" + r(s) + "\\)";
const r = String.raw;

const eq = {
  continuity: e`\frac{\partial \rho }{\partial t} + \mathbf{\nabla}\cdot(\rho \mathbf{u})=0`,
  momentum: e`\frac{\partial(\rho\mathbf{u})}{\partial t} + \mathbf{\nabla}\cdot\left(\rho \mathbf{u} \otimes \mathbf{u} \right) + \mathbf{\nabla}p = \mathbf{0}`,
  energy: e`\frac{\partial(\rho E)}{\partial t} + \mathbf{\nabla}\cdot(\rho E\mathbf{u}) + p\mathbf{\nabla}\cdot\mathbf{u} = 0`,
  damping: r`-\frac{\mu}{(1+t)^{\lambda}} \rho\mathbf{u}`,
  "1D": r`\frac{\partial}{\partial x}`,
  nDDiv: r`\mathbf{\nabla}\cdot`,
  nDGrad: r`\mathbf{\nabla}`,
  pressure: r` + \mathbf{\nabla}p`,
  velocity1DProduct: r`\mathbf{u}^2`,
  velocityNDProduct: r`\mathbf{u} \otimes \mathbf{u}`,
  nsMomentum: r`\mathbf{\nabla}\cdot\Sigma`,
  nsEnergy: r`\mathbf{\nabla}\cdot(\Sigma\cdot\mathbf{u}) - \mathbf{\nabla}\cdot\mathbf{Q}`,
  timeVariationPatern: /\\frac\{.*\}\{\\partial t\}( \+)?/g,
};

function ContinuityEquation({
  dimension,
  equilibrium,
}: {
  dimension: string;
  equilibrium: boolean;
}) {
  return (
    <>
      {!equilibrium && dimension === "1D" && (
        <Styled.Math>
          {eq.continuity
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])}
        </Styled.Math>
      )}
      {!equilibrium && dimension !== "1D" && (
        <Styled.Math>{eq.continuity}</Styled.Math>
      )}
      {equilibrium && dimension === "1D" && (
        <Styled.Math>
          {eq.continuity
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {equilibrium && dimension !== "1D" && (
        <Styled.Math>
          {eq.continuity.replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
    </>
  );
}

function MomentumEquation({
  isopressure,
  equation,
  dimension,
  equilibrium,
}: {
  isopressure: boolean;
  equation: string;
  dimension: string;
  equilibrium: boolean;
}) {
  const d = dimension === "1D";
  const t = equation.includes("Damped")
    ? 1
    : equation.includes("Navier")
    ? 2
    : 0;
  const p = isopressure;
  const e = equilibrium;

  return (
    <>
      {e && !d && t === 0 && !p && <Styled.Math>{eq.momentum}</Styled.Math>}
      {e && !d && t === 0 && p && (
        <Styled.Math>
          {eq.momentum
            .replace(eq.pressure, "")
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && !d && t === 1 && !p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.damping)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && !d && t === 1 && p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.damping)
            .replace(eq.pressure, "")
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && !d && t === 2 && !p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.nsMomentum)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && !d && t === 2 && p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.nsMomentum)
            .replace(eq.pressure, "")
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && d && t === 0 && !p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && d && t === 0 && p && (
        <Styled.Math>
          {eq.momentum
            .replace(eq.pressure, "")
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && d && t === 1 && !p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.damping)
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && d && t === 1 && p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.damping)
            .replace(eq.pressure, "")
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && d && t === 2 && !p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.nsMomentum)
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && d && t === 2 && p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.nsMomentum)
            .replace(eq.pressure, "")
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {!e && !d && t === 0 && !p && <Styled.Math>{eq.momentum}</Styled.Math>}
      {!e && !d && t === 0 && p && (
        <Styled.Math>{eq.momentum.replace(eq.pressure, "")}</Styled.Math>
      )}
      {!e && !d && t === 1 && !p && (
        <Styled.Math>{eq.momentum.replaceAll("0", eq.damping)}</Styled.Math>
      )}
      {!e && !d && t === 1 && p && (
        <Styled.Math>
          {eq.momentum.replaceAll("0", eq.damping).replace(eq.pressure, "")}
        </Styled.Math>
      )}
      {!e && !d && t === 2 && !p && (
        <Styled.Math>{eq.momentum.replaceAll("0", eq.nsMomentum)}</Styled.Math>
      )}
      {!e && !d && t === 2 && p && (
        <Styled.Math>
          {eq.momentum.replaceAll("0", eq.nsMomentum).replace(eq.pressure, "")}
        </Styled.Math>
      )}
      {!e && d && t === 0 && !p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)}
        </Styled.Math>
      )}
      {!e && d && t === 0 && p && (
        <Styled.Math>
          {eq.momentum
            .replace(eq.pressure, "")
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)}
        </Styled.Math>
      )}
      {!e && d && t === 1 && !p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.damping)
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)}
        </Styled.Math>
      )}
      {!e && d && t === 1 && p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.damping)
            .replace(eq.pressure, "")
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)}
        </Styled.Math>
      )}
      {!e && d && t === 2 && !p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.nsMomentum)
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)}
        </Styled.Math>
      )}
      {!e && d && t === 2 && p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.nsMomentum)
            .replace(eq.pressure, "")
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)}
        </Styled.Math>
      )}
    </>
  );
}

function EnergyEquation({
  dimension,
  equation,
  equilibrium,
}: {
  dimension: string;
  equation: string;
  equilibrium: boolean;
}) {
  return (
    <>
      {!equilibrium && dimension === "1D" && equation.includes("Navier") && (
        <Styled.Math>
          {eq.energy
            .replace("0", eq.nsEnergy)
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])}
        </Styled.Math>
      )}
      {!equilibrium && dimension === "1D" && !equation.includes("Navier") && (
        <Styled.Math>
          {eq.energy
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])}
        </Styled.Math>
      )}
      {!equilibrium && dimension !== "1D" && equation.includes("Navier") && (
        <Styled.Math>{eq.energy.replace("0", eq.nsEnergy)}</Styled.Math>
      )}
      {!equilibrium && dimension !== "1D" && !equation.includes("Navier") && (
        <Styled.Math>{eq.energy}</Styled.Math>
      )}
      {equilibrium && dimension === "1D" && equation.includes("Navier") && (
        <Styled.Math>
          {eq.energy
            .replace("0", eq.nsEnergy)
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {equilibrium && dimension === "1D" && !equation.includes("Navier") && (
        <Styled.Math>
          {eq.energy
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {equilibrium && dimension !== "1D" && equation.includes("Navier") && (
        <Styled.Math>
          {eq.energy
            .replace("0", eq.nsEnergy)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {equilibrium && dimension !== "1D" && !equation.includes("Navier") && (
        <Styled.Math>
          {eq.energy.replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
    </>
  );
}

const api = new CFDApi();

function CfdSimulation() {
  const [equation, setEquation] = useState("Select equation");
  const [dimension, setDimension] = useState("Select dimension");
  const [viscosity, setViscosity] = useState("Select viscosity");
  const [isothermal, setIsothermal] = useState(false);
  const [results, setResults] = useState(empty.CFDResults);
  const [isopressure, setIsopressure] = useState(false);
  const [equilibrium, setEquilibrium] = useState(false);
  const [jobId, setJobId] = useState("");
  const [finishedProject, setFinishedProject] = useState(false);

  const params = useParams();
  let state: any;
  try {
    params.state && (state = JSON.parse(params.state));
  } catch {
    state = null;
  }
  useEffect(() => {
    if (state?.action === "read") {
      setFinishedProject(true);
      projectFromJobId(state.id);
    } else if (state?.action === "run") {
      projectFromJobId(state.id);
      startSimulation();
    }
  }, []);

  let idToken: any;
  for (const [key, value] of Object.entries(localStorage)) {
    if (key?.includes("idToken")) {
      idToken = value;
    }
  }

  function projectFromJobId(jobId: string) {
    api.getCFDResults(jobId, idToken).then((res) => setResults(res.data));
  }

  function startSimulation() {
    api.solveCFD(idToken, FAST).then((resp) => {
      if (resp.status === 200) {
        setJobId(resp.data);
      }
    });
  }

  return (
    <>
      <Header />
      <Styled.Container>
        <MathJaxContext>
          <Styled.Side>
            <Styled.UseCase>CFD</Styled.UseCase>
            <Project.ProjectName>
              <Project.UnnamedProject>Unnamed project</Project.UnnamedProject>
              <Project.RenameButton title="Rename project" onClick={() => {}}>
                <Project.RenameIcon />
              </Project.RenameButton>
            </Project.ProjectName>
            <div id="Project">
              <Project.Project>
                <Project.RunButton
                  title="Run project"
                  onClick={() => startSimulation()}
                >
                  <Project.RunSCV>
                    <BiPlay />
                  </Project.RunSCV>
                  <Project.TextButton>RUN</Project.TextButton>
                </Project.RunButton>
                <Project.Button title="Save project">
                  <BiSave />
                  <Project.TextButton>SAVE</Project.TextButton>
                </Project.Button>
                <Project.Button title="Load project">
                  <BiUpload />
                  <Project.TextButton>LOAD</Project.TextButton>
                </Project.Button>
                <Project.Button title="New project">
                  <BiFileBlank />
                  <Project.TextButton>NEW</Project.TextButton>
                </Project.Button>
              </Project.Project>
            </div>
            <Styled.SideInput>
              <Styled.Header>Equation</Styled.Header>
              <Selector
                name="Equation"
                setCurrentValue={setEquation}
                currentValue={equation}
                availableValues={[
                  "Euler",
                  "Damped Euler",
                  "Laminar Navier-Stockes",
                  "Turbulent Navier-Stockes (k-eps model)",
                ]}
                defaultValue={"Select equation"}
                disabled={finishedProject}
              />
            </Styled.SideInput>
            <Styled.SideInput>
              <Styled.Header>Dimensionality</Styled.Header>
              <Selector
                name="Dimensionality"
                setCurrentValue={setDimension}
                currentValue={dimension}
                availableValues={["1D", "2D", "3D"]}
                disabled={finishedProject}
                defaultValue={"Select dimension"}
              />
              <Input.CGlobalStyles>
                <Input.CheckboxWrapper>
                  <Input.CHiddenCheckbox
                    id="time"
                    disabled={finishedProject}
                    onChange={(e) => setEquilibrium(e.target.checked)}
                  />
                  <Input.CLabel htmlFor="time">
                    Stationary analysis
                  </Input.CLabel>
                </Input.CheckboxWrapper>
              </Input.CGlobalStyles>
            </Styled.SideInput>
            <Styled.SideInput>
              <Styled.Header>Properties</Styled.Header>
              <Styled.Form>
                <Input.CGlobalStyles>
                  <Input.CheckboxWrapper>
                    <Input.CHiddenCheckbox
                      name="properties"
                      id="compressible"
                      disabled={finishedProject}
                    />
                    <Input.CLabel htmlFor="compressible">
                      Incompressible fluid
                    </Input.CLabel>
                  </Input.CheckboxWrapper>
                </Input.CGlobalStyles>
                <Input.CGlobalStyles>
                  <Input.CheckboxWrapper>
                    <Input.CHiddenCheckbox
                      name="properties"
                      id="constant"
                      disabled={finishedProject}
                      onChange={(e) => {
                        setIsopressure(e.target.checked);
                      }}
                    />
                    <Input.CLabel htmlFor="constant">
                      Constant pressure
                    </Input.CLabel>
                  </Input.CheckboxWrapper>
                </Input.CGlobalStyles>
                <Input.CGlobalStyles>
                  <Input.CheckboxWrapper>
                    <Input.CHiddenCheckbox
                      name="properties"
                      id="isothermal"
                      disabled={finishedProject}
                      onChange={(e) => {
                        setIsothermal(e.target.checked);
                      }}
                    />
                    <Input.CLabel htmlFor="isothermal">Isothermal</Input.CLabel>
                  </Input.CheckboxWrapper>
                </Input.CGlobalStyles>
                <Input.CGlobalStyles>
                  <Input.CheckboxWrapper>
                    <Input.CHiddenCheckbox
                      name="properties"
                      id="acceleration"
                      disabled={finishedProject}
                    />
                    <Input.CLabel htmlFor="acceleration">
                      External acceleration
                    </Input.CLabel>
                  </Input.CheckboxWrapper>
                </Input.CGlobalStyles>
                <Input.CGlobalStyles>
                  <Input.CheckboxWrapper>
                    <Input.CHiddenCheckbox
                      name="properties"
                      id="species"
                      disabled={finishedProject}
                    />
                    <Input.CLabel htmlFor="species">Multi-species</Input.CLabel>
                  </Input.CheckboxWrapper>
                </Input.CGlobalStyles>
              </Styled.Form>
            </Styled.SideInput>
          </Styled.Side>
          <Styled.Center>
            {(equation.includes("Euler") || equation.includes("Navier")) && (
              <Styled.Equations>
                <Styled.Formulas>
                  <>
                    <ContinuityEquation
                      dimension={dimension}
                      equilibrium={equilibrium}
                    />
                    <MomentumEquation
                      isopressure={isopressure}
                      equation={equation}
                      dimension={dimension}
                      equilibrium={equilibrium}
                    />
                    {!isothermal && (
                      <EnergyEquation
                        dimension={dimension}
                        equation={equation}
                        equilibrium={equilibrium}
                      />
                    )}
                  </>
                </Styled.Formulas>
                <Styled.Functions>
                  <Styled.Math>{e`\rho:\text{ mass density }(kg.m^{−3})`}</Styled.Math>
                  <Styled.Math>{e`\mathbf{u}:\text{ fluid velocity vector }(m.s^{−1})`}</Styled.Math>
                  {!isothermal && (
                    <Styled.Math>{e`E:\text{ energy density }(J.kg^{−1})`}</Styled.Math>
                  )}
                </Styled.Functions>
              </Styled.Equations>
            )}
            {results.density.length === 0 && (
              <JobProgressBar
                jobId={jobId}
                callback={() =>
                  api
                    .getCFDResults(jobId, idToken)
                    .then((res) => setResults(res.data))
                }
              />
            )}
            {results.density.length !== 0 && (
              <Styled.Results>
                <Styled.Graphs>
                  <Plot3D
                    data={results.velocity}
                    name="Flow velocity"
                    axes={{ x: "x (mm)", y: "t (s)", z: "u (m/s)" }}
                  />
                  <Plot3D
                    data={results.density}
                    name="Flow density"
                    axes={{ x: "x (mm)", y: "t (s)", z: "rho (kg/m³)" }}
                  />
                </Styled.Graphs>
                <Styled.ResultPane>
                  <h3> Technical KPIs </h3>
                  <p> Using the OVH Qaptiva </p>
                  <p style={{ margin: 0 }}> Run time: 2.1s </p>
                  <p style={{ margin: 0 }}> Mean error: 0.8% </p>
                  <p style={{ margin: 0 }}> Max error: 1.5% </p>
                  <h3 style={{ marginTop: "5em" }}>Advantage over classical</h3>
                  <p> Using an OVH box with AMD EPYC 9754 and 128G of memory</p>
                  <p style={{ margin: 0 }}> +32% precision </p>
                  <p style={{ margin: 0 }}> -11% cost </p>
                  <p style={{ margin: 0 }}> -46% energy consumption </p>
                  <h3 style={{ marginTop: "5em" }}>
                    Comparison with a classical solver
                  </h3>
                  <img
                    style={{ width: "20rem" }}
                    src="/images/cfd-classical_comparison.png"
                    alt="Comparison with a classical solver"
                  />
                </Styled.ResultPane>
              </Styled.Results>
            )}

            {results.density.length !== 0 && (
              <div style={{ display: "flex", gap: "2em", marginTop: "5em" }}>
                <UploadBox name="Upload geometric profile" />
                <UploadBox name="Upload boundary conditions file" />
              </div>
            )}
          </Styled.Center>
          {equation.includes("Damped Euler") && (
            <>
              <DropDown
                setViscosity={setViscosity}
                viscosity={viscosity}
                isSet={equation.includes("Damped Euler")}
              />
            </>
          )}

          {equation.includes("Navier-Stockes") && (
            <>
              <Styled.Side>
                <Styled.SideInput>
                  <Styled.Header style={{ display: "flex" }}>
                    Viscosity stress tensor{" "}
                    <Styled.MathWhite>{e`\Sigma`}</Styled.MathWhite>
                  </Styled.Header>
                  <Selector
                    name="Viscosity"
                    setCurrentValue={setViscosity}
                    currentValue={viscosity}
                    availableValues={[]}
                    disabled={finishedProject}
                    defaultValue={"Select viscosity"}
                  />
                </Styled.SideInput>

                <Styled.SideInput>
                  <Styled.Header style={{ display: "flex" }}>
                    Heat Flow{" "}
                    <Styled.MathWhite
                      style={{ marginBlock: 0 }}
                    >{e`\mathbf{Q}`}</Styled.MathWhite>
                  </Styled.Header>
                  <Selector
                    name="Viscosity"
                    setCurrentValue={setViscosity}
                    disabled={finishedProject}
                    currentValue={viscosity}
                    availableValues={[]}
                    defaultValue={"Select viscosity"}
                  />
                </Styled.SideInput>
              </Styled.Side>
            </>
          )}
        </MathJaxContext>
      </Styled.Container>
    </>
  );
}

function DropDown(setViscosity: any, viscosity: any, isSet: any) {
  return (
    <Styled.Side>
      {isSet ? (
        <Styled.SideInput>
          <Styled.Header>Viscosity stress tensor</Styled.Header>
          <Selector
            name="Viscosity"
            setCurrentValue={setViscosity}
            currentValue={viscosity}
            availableValues={[]}
            defaultValue={"Select viscosity"}
          />
        </Styled.SideInput>
      ) : (
        ""
      )}
      <Styled.SideInput>
        <Styled.Header>Parameters</Styled.Header>
        <Styled.ParamInput>
          <Styled.Label htmlFor="lambda">
            <Styled.MathWhite>{e`\lambda: `}</Styled.MathWhite>
          </Styled.Label>
          <Styled.FInput
            name="lambda"
            coord="lambda"
            location={{ lambda: 1 }}
            disabled={false}
            setter={() => {}}
          />
        </Styled.ParamInput>
        <Styled.ParamInput>
          <Styled.Label htmlFor="t">
            <Styled.MathWhite>{e`\mu: `}</Styled.MathWhite>
          </Styled.Label>
          <Styled.FInput
            name="mu"
            coord="mu"
            location={{ mu: 1 }}
            disabled={false}
            setter={() => {}}
          />
        </Styled.ParamInput>
      </Styled.SideInput>
    </Styled.Side>
  );
}
export default CfdSimulation;
