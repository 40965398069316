import * as Styled from "./style";

type Product = { formula: string; coefficient: number };
type Reactant = Product & { initialQuantity: number };
type ChemicalReaction = { reactants: Reactant[]; products: Product[] };

function reactionHandSite(products: Product[]) {
  return products
    .map(
      (product) =>
        (product.coefficient === 1 ? "" : product.coefficient + " ") +
        product.formula +
        " "
    )
    .join("+");
}

function RenderedChemicalReaction({
  reaction,
}: {
  reaction: ChemicalReaction;
}) {
  return (
    <Styled.Reaction>
      <Styled.ReactionText>
        {reactionHandSite(reaction.reactants) +
          "➡ " +
          reactionHandSite(reaction.products)}
      </Styled.ReactionText>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {reaction.reactants.map((reactant) => (
          <Styled.ReactionText key={reactant.formula}>
            {reactant.formula + ": " + reactant.initialQuantity + " mol"}
          </Styled.ReactionText>
        ))}
      </div>
    </Styled.Reaction>
  );
}

export default RenderedChemicalReaction;
