import { FlameResult, ProjectIds } from "../../../api";

export const projectIds: ProjectIds = {
  id: "none",
  name: "",
};

export const CFDResults: FlameResult = {
  mass: [],
  temperature: [],
};
