import { CFDResult, ProjectIds } from "../../../api";

export const projectIds: ProjectIds = {
  id: "none",
  name: "",
};

export const CFDResults: CFDResult = {
  velocity: [],
  density: [],
};
