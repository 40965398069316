import styled from "styled-components";
import { MdDriveFileRenameOutline } from "react-icons/md";

type InputArgument = {
  disabled?: boolean;
};

export const Project = styled.div`
  display: flex;
  margin: 1rem;
  justify-content: space-between;
  border-width: 1px 0 0 0;
  vertical-align: middle;
  cursor: pointer;
  align-items: end;
`;

export const RenameIcon = styled(MdDriveFileRenameOutline)`
  padding-bottom: -20rem;
`;

export const UnnamedProject = styled.span`
  font-style: italic;
`;

export const ProjectName = styled.div`
  font-size: 1.2rem;
  padding-top: 0.5rem;
`;

export const ProjectChoice = styled.h3`
  display: flex;

  button {
    color: black;
  }
`;

export const Button = styled.button`
  background-color: transparent;
  border-width: 0;
  color: white;
  outline: none;
  font-size: 2.1rem;
  filter: drop-shadow(2px 2px 2px black);
  transition: all 0.1s ease-in;
  vertical-align: middle;

  &:hover {
    transform: translateY(-1px);
    transform: ${({ disabled = false }: InputArgument) =>
      disabled ? "scale(1)" : "scale(1.15)"};
    /* font-size: 2.2rem; */
  }
  cursor: ${({ disabled = false }: InputArgument) =>
    disabled ? "not-allowed" : "pointer"};
`;

export const BlackButton = styled(Button)`
  color: black;
`;

export const RenameButton = styled(Button)`
  font-size: 1.2rem;
  position: relative;
`;

export const RunButton = styled(Button)`
  font-size: 2.5rem;
  margin-right: -0.8rem;
`;

export const RunSCV = styled.div`
  transform: translateY(5px);
`;

export const TextButton = styled.p`
  font-size: 0.7rem;
  margin: 0rem;
`;
