import { MathJax } from "better-react-mathjax";
import styled, { keyframes } from "styled-components";
import FloatInput from "../../../components/FloatInput";

type InputArgument = {
  disabled?: boolean;
};

export const CustomMaterial = styled.div`
  display: ${({ visible }: { visible: boolean }) =>
    visible ? "block" : "none"};
`;

export const Form = styled.form`
  text-align: left;
`;

export const ParamInput = styled.div`
  display: flex;
`;

export const ChemicalReactions = styled.div`
  border-radius: 1em;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  color: black;
  padding: 1em;
  width: 60%;
  align-items: center;
`;

export const ReactionText = styled.div`
  background: white;
  color: black;
  font-family: "Courier New", Courier, monospace;
  font-weight: 600;
  padding: 0.5em;
  margin: 1em;
  border-radius: 0.25em;
`;

export const Reaction = styled.div`
  display: grid;
  width: 80%;
  grid-template-columns: 50% 50%;
`;

export const Input = styled.input`
  box-shadow: 0.1rem 0.1rem black;
  border-radius: 0.25rem;
  padding: 0.1rem;
  font-size: 0.9rem;
  max-width: 70%;
  cursor: ${({ disabled = false }: InputArgument) =>
    disabled ? "not-allowed" : "default"};
`;

export const FInput = styled(FloatInput)`
  box-shadow: 0.1rem 0.1rem black;
  border-radius: 0.25rem;
  padding: 0.1rem;
  font-size: 0.9rem;
  max-width: 70%;
  cursor: ${({ disabled = false }: InputArgument) =>
    disabled ? "not-allowed" : "default"};
`;

const slideInFromLeft = keyframes`
  from {
    transform: translateX(+100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const SideInput = styled.div`
  margin: 0.5rem;

  input,
  select {
    margin: 0.5rem;
  }
`;

export const ExtraSideInput = styled(SideInput)`
  animation: ${slideInFromLeft} 0.3s ease forwards;
`;

export const MultipleParameterDiv = styled.div`
  border: 2.5px solid #5f5f5f;
`;

export const HeadlessHeader = styled.h3`
  font-weight: bold;
  font-size: 1.4rem;
  padding: 0;
  margin-top: 0;
`;

export const Header = styled(HeadlessHeader)`
  filter: drop-shadow(1px 1px 1px black);
  border-color: white;
  border-style: solid;
  border-width: 1px 0 0 0;
  padding-top: 1rem;
  margin-bottom: 1.5rem;
`;

export const SubHeader = styled(Header)`
  border-width: 0;
  margin: 0rem 1rem 1rem 1rem;
`;

export const Label = styled.label`
  filter: drop-shadow(0.5px 1px 1px black);
  font-weight: "400";
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100vh - 5.5rem);
  overflow: hidden;
  position: relative;
`;

export const Side = styled.div`
  flex: 0.1;
  filter: drop-shadow(1px 1px 1px black);
  color: white;
  background: radial-gradient(
    circle at 50% 20%,
    rgb(43 62 133) 5%,
    rgb(20 34 85) 90%
  );
  padding: 0.5rem;
  text-align: center;
  position: relative;
`;

export const ExtraSide = styled(Side)`
  animation: ${slideInFromLeft} 0.3s ease;
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 1rem;
  width: 100%;
`;

export const ResultGraphs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Results = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const ResultImage = styled.img`
  max-width: 100%;
  margin: auto;
  padding: 1em;

  @media (min-width: 1080px) {
    max-width: 60%;
  }
`;

export const ModalBackground = styled.div`
  display: ${({ hide }: { hide: boolean }) => (hide ? "none" : "block")};
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalContent = styled.div`
  display: ${({ hide }: { hide: boolean }) => (hide ? "none" : "block")};
  background-color: #fefefe;
  margin: auto;
  padding: 10px;
  border: 1px solid #888;
  width: max-content;
  border-radius: 0.5em;
`;

export const Close = styled.span`
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;

  :hover,
  :focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const Math = styled(MathJax)`
  font-size: 1em;
  margin: 0.5em;
  color: black;
`;

export const MathWhite = styled(Math)`
  color: white;
`;

export const UseCase = styled.h3`
  filter: drop-shadow(1px 1px 1px black);
  font-weight: bold;
  font-size: 1.4rem;
  border-color: white;
  border-style: solid;
  border-width: 0 0 1px 0;
  padding: 0 0 1rem 0;
`;
