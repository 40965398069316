import { useEffect, useState } from "react";
import {
  BiFileBlank,
  BiPlay,
  BiPlusCircle,
  BiSave,
  BiUpload,
} from "react-icons/bi";
import { FlameApi } from "../../../api";
import Header from "../../../components/header/Header";
import JobProgressBar from "../../../components/JobProgressBar";

import { MathJaxContext } from "better-react-mathjax";
import { useParams } from "react-router-dom";
import { SimplePlot2D } from "../../../components/plot";
import Selector from "../../../components/Selector";
import * as Input from "../../../components/use_case/input";
import * as Project from "../../../components/use_case/project";
import UploadBox from "../../../components/UploadComponents";
import { FAST } from "../../../fast-solve";
import RenderedChemicalReaction from "./ChemicalReaction";
import * as empty from "./emptyInitializers";
import * as Styled from "./style";

const e = (s: TemplateStringsArray) => "\\(" + r(s) + "\\)";
const r = String.raw;

const eq = {
  mass: e`{\dot m}\,\partial_x Y_1 - \frac{\lambda}{C_p}  Le^{-1}\partial^2_x Y_1 = -{A}TY_1`,
  temperature: e`{\dot m}\,\partial_x T - \frac{\lambda}{C_p} \partial^2_x T = \Delta_h\tilde{A}TY_1`,
};

const nop = () => {};

const api = new FlameApi();

function FlameSimulation() {
  const [equation, setEquation] = useState("Select equation");
  const [dimension, setDimension] = useState("Select dimension");
  const [viscosity, setViscosity] = useState("Select viscosity");
  const [acceleration, setAcceleration] = useState(false);
  const [results, setResults] = useState(empty.CFDResults);
  const [finishedProject, setFinishedProject] = useState(false);
  const [jobId, setJobId] = useState("");
  const chemicalReactions = [
    {
      reactants: [
        { formula: "CH4", coefficient: 1, initialQuantity: 10 },
        { formula: "02", coefficient: 2, initialQuantity: 20 },
      ],
      products: [
        { formula: "CO2", coefficient: 1 },
        { formula: "H2O", coefficient: 2 },
      ],
    },
  ];

  const params = useParams();
  let state: any;
  try {
    params.state && (state = JSON.parse(params.state));
  } catch {
    state = null;
  }
  useEffect(() => {
    if (state?.action === "read") {
      setFinishedProject(true);
      projectFromJobId(state.id);
    } else if (state?.action === "run") {
      projectFromJobId(state.id);
      startSimulation();
    }
  }, []);
  let idToken: any;
  for (const [key, value] of Object.entries(localStorage)) {
    if (key?.includes("idToken")) {
      idToken = value;
    }
  }

  function projectFromJobId(jobId: string) {
    api.getFlameResults(jobId, idToken).then((res) => setResults(res.data));
  }

  function startSimulation() {
    api.solveFlame(idToken, FAST).then((resp) => {
      if (resp.status === 200) {
        setJobId(resp.data);
      }
    });
  }

  return (
    <>
      <Header />
      <Styled.Container>
        <MathJaxContext>
          <Styled.Side>
            <Styled.UseCase>Combustion</Styled.UseCase>
            <Project.ProjectName>
              <Project.UnnamedProject>Unnamed project</Project.UnnamedProject>
              <Project.RenameButton title="Rename project" onClick={() => {}}>
                <Project.RenameIcon />
              </Project.RenameButton>
            </Project.ProjectName>
            <div id="Project">
              <Project.Project>
                <Project.RunButton
                  title="Run project"
                  onClick={() => startSimulation()}
                >
                  <Project.RunSCV>
                    <BiPlay />
                  </Project.RunSCV>
                  <Project.TextButton>RUN</Project.TextButton>
                </Project.RunButton>
                <Project.Button title="Save project">
                  <BiSave />
                  <Project.TextButton>SAVE</Project.TextButton>
                </Project.Button>
                <Project.Button title="Load project">
                  <BiUpload />
                  <Project.TextButton>LOAD</Project.TextButton>
                </Project.Button>
                <Project.Button title="New project">
                  <BiFileBlank />
                  <Project.TextButton>NEW</Project.TextButton>
                </Project.Button>
              </Project.Project>
            </div>
            <Styled.SideInput>
              <Styled.Header>Equation</Styled.Header>
              <Selector
                name="Equation"
                setCurrentValue={setEquation}
                currentValue={equation}
                availableValues={[
                  "Euler combustion",
                  "Laminar Navier-Stockes combustion",
                  "Turbulent Navier-Stockes combustion",
                ]}
                disabled={finishedProject}
                defaultValue={"Select equation"}
              />
            </Styled.SideInput>
            <Styled.SideInput>
              <Styled.Header>Dimensionality</Styled.Header>
              <Selector
                name="Dimensionality"
                setCurrentValue={setDimension}
                currentValue={dimension}
                availableValues={["1D", "2D", "3D"]}
                disabled={finishedProject}
                defaultValue={"Select dimension"}
              />
              <Input.CGlobalStyles>
                <Input.CheckboxWrapper>
                  <Input.CHiddenCheckbox id="time" />
                  <Input.CLabel htmlFor="time">
                    Stationary analysis
                  </Input.CLabel>
                </Input.CheckboxWrapper>
              </Input.CGlobalStyles>
            </Styled.SideInput>
            <Styled.SideInput>
              <Styled.Header>Reactants mixing</Styled.Header>
              <Styled.Form>
                <Input.CGlobalStyles>
                  <Input.CheckboxWrapper>
                    <Input.CHiddenCheckbox
                      name="mixing"
                      id="premixed"
                      disabled={finishedProject}
                    />
                    <Input.CLabel htmlFor="premixed">Premixed</Input.CLabel>
                  </Input.CheckboxWrapper>
                </Input.CGlobalStyles>

                <Input.CGlobalStyles>
                  <Input.CheckboxWrapper>
                    <Input.CHiddenCheckbox
                      name="mixing"
                      id="partial"
                      disabled={finishedProject}
                    />
                    <Input.CLabel htmlFor="partial">
                      Partially premixed
                    </Input.CLabel>
                  </Input.CheckboxWrapper>
                </Input.CGlobalStyles>

                <Input.CGlobalStyles>
                  <Input.CheckboxWrapper>
                    <Input.CHiddenCheckbox
                      name="mixing"
                      id="counterflow"
                      disabled={finishedProject}
                    />
                    <Input.CLabel htmlFor="counterflow">
                      Counterflow
                    </Input.CLabel>
                  </Input.CheckboxWrapper>
                </Input.CGlobalStyles>
              </Styled.Form>
            </Styled.SideInput>
            <Styled.SideInput>
              <Styled.Header>Flow properties</Styled.Header>
              <Styled.Form>
                <Input.CGlobalStyles>
                  <Input.CheckboxWrapper>
                    <Input.CHiddenCheckbox
                      name="flow"
                      id="acceleration"
                      disabled={finishedProject}
                      onChange={(e) => setAcceleration(e.target.checked)}
                    />
                    <Input.CLabel htmlFor="acceleration">
                      External acceleration
                    </Input.CLabel>
                  </Input.CheckboxWrapper>
                </Input.CGlobalStyles>
              </Styled.Form>
            </Styled.SideInput>
          </Styled.Side>
          <Styled.Center>
            <Styled.Math>{eq.mass}</Styled.Math>
            <Styled.Math>{eq.temperature}</Styled.Math>
            {results.mass.length === 0 && (
              <JobProgressBar
                jobId={jobId}
                callback={() =>
                  api.getFlameResults(jobId, idToken).then((res) => {
                    setResults(res.data);
                  })
                }
              />
            )}
            {results.mass.length === 0 && (
              <Styled.ChemicalReactions>
                <Styled.HeadlessHeader>
                  Chemical reactions
                </Styled.HeadlessHeader>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "80%",
                  }}
                >
                  <div>
                    <BiPlusCircle
                      style={{
                        fontSize: "1.5em",
                        marginRight: ".25em",
                        cursor: "pointer",
                        translate: "0 .25em",
                      }}
                    />
                    Add chemical reaction
                  </div>
                  <div>Initial reactant quantities (mol)</div>
                </div>
                {chemicalReactions.map((reaction) => (
                  <RenderedChemicalReaction
                    reaction={reaction}
                    key={JSON.stringify(reaction)}
                  />
                ))}
              </Styled.ChemicalReactions>
            )}
            {results.mass.length === 0 && (
              <div style={{ display: "flex", gap: "2em", marginTop: "5em" }}>
                <UploadBox name="Upload geometric profile" />
                <UploadBox name="Upload boundary conditions file" />
              </div>
            )}
            {results.mass.length !== 0 && (
              <Styled.Results>
                <Styled.ResultGraphs>
                  <SimplePlot2D data={results.mass} name="mass fraction" />
                  <SimplePlot2D data={results.temperature} name="temperature" />
                </Styled.ResultGraphs>
                <div style={{ alignContent: "center" }}>
                  <img
                    style={{ width: "30rem" }}
                    src="/images/flame_diagram.gif"
                    alt="diagram of the flame"
                  />
                </div>
              </Styled.Results>
            )}
          </Styled.Center>
          {(equation.includes("Navier") || acceleration) && (
            <>
              <Styled.ExtraSide>
                {equation.includes("Navier") && (
                  <Styled.ExtraSideInput>
                    <Styled.Header>Viscosity stress tensor</Styled.Header>
                    <Selector
                      name="Viscosity"
                      setCurrentValue={setViscosity}
                      currentValue={viscosity}
                      disabled={finishedProject}
                      availableValues={[]}
                      defaultValue={"Select viscosity"}
                    />
                  </Styled.ExtraSideInput>
                )}
                {acceleration && (
                  <Styled.ExtraSideInput>
                    <Styled.Header>Parameters</Styled.Header>
                    <p>External acceleration vector</p>
                    <Styled.ParamInput>
                      <Styled.Label htmlFor="x">
                        <Styled.MathWhite>{e`x: `}</Styled.MathWhite>
                      </Styled.Label>
                      <Styled.FInput
                        name="x"
                        disabled={finishedProject}
                        coord="x"
                        location={{ x: 1 }}
                        setter={nop}
                      />
                    </Styled.ParamInput>
                    <Styled.ParamInput>
                      <Styled.Label htmlFor="y">
                        <Styled.MathWhite>{e`y: `}</Styled.MathWhite>
                      </Styled.Label>
                      <Styled.FInput
                        name="y"
                        coord="y"
                        disabled={finishedProject}
                        location={{ y: 1 }}
                        setter={nop}
                      />
                    </Styled.ParamInput>
                    <Styled.ParamInput>
                      <Styled.Label htmlFor="z">
                        <Styled.MathWhite>{e`z: `}</Styled.MathWhite>
                      </Styled.Label>
                      <Styled.FInput
                        name="z"
                        coord="z"
                        disabled={finishedProject}
                        location={{ z: 1 }}
                        setter={nop}
                      />
                    </Styled.ParamInput>
                  </Styled.ExtraSideInput>
                )}
              </Styled.ExtraSide>
            </>
          )}
        </MathJaxContext>
      </Styled.Container>
    </>
  );
}

export default FlameSimulation;
