import logo from "./quick_logo.png";
import React, { useState } from "react";
import BurgerButton from "./burger_button/BurgerButton";
import RightNav from "./right_nav/RightNav";
import { getHostName } from "../../utils";
import styled from "styled-components";

// TODO : use .svg instead of .png for the logo, and in white for the dark theme.

const StyledLogoLink = styled.a`
  text-align: center;
  vertical-align: middle;
  grid-column: 2 / 3;
`;

const StyledLogo = styled.img`
  height: 5em;
  width: auto;
  margin-top: 0.2rem;
  filter: drop-shadow(2px 2px 3px lightgrey);
  transition: transform 0.2s ease;

  :hover {
    transform: scale(1.02);
  }
`;

const StyledHeader = styled.header`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  min-width: 100%;
  height: max-content;
  box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.16),
    5px 5px 5px 3px rgba(0, 0, 0, 0.12);
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 2;
  top: 0;
  position: fixed;
`;

const DivHeader = styled.header`
  width: 100%;
  min-width: 100%;
  height: 87px;
  background-color: rgba(255, 255, 255, 0);
  position: relative;
`;

/**
 *
 * This function creates the header of the website. It includes the Logo of the company, the {@link BurgerButton} and the {@link RightNav}.
 *
 */
function Header() {
  const [open, setOpen] = useState(false);

  function toggleMenu() {
    setOpen(!open);
  }

  return (
    <DivHeader>
      <StyledHeader>
        <StyledLogoLink href={getHostName()}>
          <StyledLogo src={logo} alt="QUICK logo" />
        </StyledLogoLink>
        <BurgerButton
          open={open}
          onClick={toggleMenu}
          dimentions={{ spacing: 3, size: 24, thickness: 4 }}
        />
        <RightNav visible={open} />
      </StyledHeader>
    </DivHeader>
  );
}

export default Header;
