import React, { useEffect, useState } from "react";
import { HiCheckCircle, HiClock } from "react-icons/hi";
import { IoCloseCircle } from "react-icons/io5";
import styled from "styled-components";
import { GeneralApi } from "../../api";
import Selector from "../../components/Selector";
import Header from "../../components/header/Header";
import {
  StyledContainer,
  StyledContent,
  StyledTitle,
} from "../../components/monopager/Monopager";
import { StyledRuler } from "../../components/monopager/item/MonopagerItem";

const StyledNav = styled.nav`
  width: max(200px, 10%);
  color: white;
  background: radial-gradient(
    circle at 50% 20%,
    rgb(43 62 133) 5%,
    rgb(20 34 85) 90%
  );
  box-shadow: 5px 0 5px -3px rgba(0, 0, 0, 0.16),
    5px 0 5px -3px rgba(0, 0, 0, 0.12);
  padding: 10px;
`;

const StyledCustomContainer = styled(StyledContainer)`
  min-height: calc(100vh - 5.5rem);
  height: fit-content;
`;

const generalApi = new GeneralApi();

enum Status {
  Completed = "Completed",
  Ongoing = "Ongoing",
  Cancelled = "Cancelled",
}

type Job = {
  id: string;
  name: string;
  jobType: string;
  dateStarted: string;
  price: number;
  status: Status;
};

const currency = "€";

const nameList = [
  "Steel Beam",
  "Wood Ellipse",
  "Iron Axe",
  "Silver Plate",
  "Glass Cylinder",
  "Gold Ring",
];

function statusIcon(status: Status) {
  switch (status) {
    case Status.Completed:
      return <HiCheckCircle color="green" />;
    case Status.Ongoing:
      return <HiClock color="yellow" />;
    case Status.Cancelled:
      return <IoCloseCircle color="red" />;
    default:
      return "Unimplemented";
  }
}

function navigateToUseCase(jobType: string, jobId: string, action: string) {
  const jobTypeToPage: Record<string, string> = {
    md: "material_deformation",
    cfd: "computational_fluid-dynamics",
    combustion: "combustion",
  };
  window.location.replace(
    "/" +
      jobTypeToPage[jobType] +
      "/" +
      JSON.stringify({ id: jobId, action: action })
  );
}

export default function Jobs({ offlineCall }: { offlineCall?: boolean }) {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [visibleJobs, setVisibleJobs] = useState<Job[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [currentFilter, setFilter] = useState<string>("All");

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setVisibleJobs(
      jobs
        .filter(
          (job) => currentFilter === "All" || job.status === currentFilter
        )
        .slice(startIndex, endIndex)
    );
  }, [jobs, currentPage, currentFilter]);

  let idToken: any;
  for (const [key, value] of Object.entries(localStorage)) {
    if (key?.includes("idToken")) {
      idToken = value;
    }
  }

  function getJobs(offlineCall?: boolean) {
    if (offlineCall) {
      setJobs(getJobsNoBackend());
    } else {
      generalApi.getJobs(undefined, idToken).then(async (res) => {
        const jobsData = await Promise.all(
          res.data.map(async (jobId) => {
            return await generalApi.getJob(jobId, idToken).then((res) => {
              const job = res.data;
              return {
                id: jobId,
                name: nameList[Math.floor(Math.random() * nameList.length)],
                jobType: job.type,
                status:
                  job.progress === 100
                    ? Status.Completed
                    : job.end_date
                    ? Status.Cancelled
                    : Status.Ongoing,
                dateStarted: job.start_date
                  .split("T")[0]
                  .split("-")
                  .reverse()
                  .join("/"),
                price: Math.floor(Math.random() * 1000),
              };
            });
          })
        );
        setJobs(jobsData);
      });
    }
  }

  function getJobsNoBackend() {
    return [
      {
        id: "j-1",
        name: "Silver Plate",
        jobType: "md",
        status: Status.Ongoing,
        dateStarted: "14/11/2022",
        price: 522,
      },
      {
        id: "j-2",
        name: "Gold Ring",
        jobType: "md",
        status: Status.Completed,
        dateStarted: "14/11/2022",
        price: 158,
      },
    ];
  }

  const totalPages = Math.ceil(
    jobs.filter(
      (job) => currentFilter === "All" || job.status === currentFilter
    ).length / itemsPerPage
  );

  return (
    <React.Fragment>
      <Header />
      <StyledCustomContainer>
        <StyledNav>
          <StyledTitle> Jobs </StyledTitle>
          <StyledRuler />
          Filter results
          <Selector
            name="Filter"
            currentValue={currentFilter}
            setCurrentValue={(filter) => {
              setCurrentPage(1);
              setFilter(filter);
            }}
            availableValues={["All"].concat(Object.keys(Status))}
            showDefault={false}
          />
        </StyledNav>
        <StyledContent>
          <div>
            <table cellPadding="4" cellSpacing="4">
              <tbody>
                <tr>
                  <th> Type </th>
                  <th> Status </th>
                  <th> Name </th>
                  <th> Job ID </th>
                  <th> Date Started </th>
                  <th> Price </th>
                  <th> Action </th>
                </tr>

                {visibleJobs.map((job) => (
                  <tr key={job.id}>
                    <td>{job.jobType}</td>
                    <td>
                      {statusIcon(job.status)} {job.status}
                    </td>
                    <td>{job.name}</td>
                    <td>{job.id}</td>
                    <td>{job.dateStarted}</td>
                    <td>
                      {job.status !== Status.Ongoing && job.price + currency}
                    </td>
                    <td>
                      {job.status === Status.Ongoing && (
                        <button
                          onClick={() => {
                            generalApi.cancelJob(job.id, idToken);
                            getJobs();
                          }}
                        >
                          Cancel
                        </button>
                      )}
                      {job.status === Status.Completed && (
                        <button
                          onClick={() =>
                            navigateToUseCase(job.jobType, job.id, "read")
                          }
                        >
                          View results
                        </button>
                      )}
                      {job.status === Status.Cancelled && (
                        <button
                          onClick={() =>
                            navigateToUseCase(job.jobType, job.id, "run")
                          }
                        >
                          Relaunch
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </StyledContent>
      </StyledCustomContainer>
    </React.Fragment>
  );
}
