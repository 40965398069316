import { useState } from "react";
import styled, { keyframes } from "styled-components";
import { signOut } from "../../../pages/login";
import { routes } from "../../../routes";
import { getHostName } from "../../../utils";

const StyledItem = styled.li`
  margin: 0em;
  text-align: left;
  background: #0c247a;
  border-radius: 0px;
  width: 100%;
  font-size: 1.2rem;
  text-shadow: 1px 1px 3px black;
  filter: drop-shadow(0px 1px 1px black);
  color: #e7ecf8;
  transition: transform 0.2s, color 0.2s ease-out, background 0.2s ease-out;
  transform: translateX(0px);

  :hover {
    text-shadow: 3px 3px 4px black;
    background: #1c3db5;
  }
`;

const TextMover = styled.p`
  padding: 10px 20px;
  margin: 0;
  transition: transform 0.2s, color 0.2s ease-out, background 0.2s ease-out;

  :hover {
    transform: translateX(10px);
  }
`;

const LinkButton = styled(StyledItem)`
  color: rgb(255, 95, 95);
  bottom: 1.61em;
  position: fixed;
`;

const BottomItem = styled(StyledItem)`
  position: fixed;
  bottom: 4.61em;
`;

const sideBarAnimation = keyframes`
    0% {right:-300px; opacity:0} 
    100% {right:0; opacity:1}
`;

const StyledSideBar = styled.ul`
  display: ${({ visible }: { visible: boolean }) =>
    visible ? "block" : "none"};
  right: 0;
  bottom: 150vh;
  top: 87.2px;
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 20px 0px;
  list-style-type: none;
  height: 100%;
  width: 200px;
  background-color: rgba(255, 255, 255, 0.98);
  z-index: 1;
  overflow: absolute;
  box-shadow: -10px 5px 10px 0px rgba(0, 0, 0, 0.25),
    -5px 0 5px -3px rgba(0, 0, 0, 0.12);
  position: fixed;
  animation-name: ${sideBarAnimation};
  animation-duration: 0.4s;
`;

const StyledA = styled.a`
  color: white;
`;

/**
 *
 * This creates the Right navigation menu that is open when one clicks on the Burger Button.
 * @param props
 * @param props.visible visible is a boolean that tells whether the menu is visible or not.
 */
export default function RightNav({ visible }: { visible: boolean }) {
  const desiredLinks = ["Profile", "Jobs", "Help"];
  const [isConnectedValue, setIsConnectedValue] = useState(true);

  let links = new Map<string, string>();
  routes.forEach((route) => {
    if (route.linkName && desiredLinks.includes(route.linkName)) {
      links.set(route.linkName, getHostName() + route.path);
    }
  });

  return (
    <StyledSideBar visible={visible}>
      <>{!isConnectedValue ? (window.location.href = "/login") : null}</>

      {desiredLinks.map((key) => {
        return (
          <StyledItem key={key}>
            <StyledA href={links.get(key)}>
              <TextMover>{key}</TextMover>
            </StyledA>
          </StyledItem>
        );
      })}
      <LinkButton
        onClick={() => {
          signOut().then(() => setIsConnectedValue(false));
        }}
      >
        <TextMover>&#8618; Disconnect</TextMover>
      </LinkButton>

      <BottomItem>
        <StyledA href={window.location.href + "#"}>
          <TextMover>Contact us</TextMover>
        </StyledA>
      </BottomItem>
    </StyledSideBar>
  );
}
