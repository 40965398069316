import { useState } from "react";
import styled from "styled-components";
import MonopagerItem, {
  MonopagerItemData,
  StyledRuler,
} from "./item/MonopagerItem";

type MonopagerArguments = {
  page_title: string;
  items: MonopagerItemData[];
};

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: black;
  height: calc(100vh - 5.5rem);
`;

const StyledNav = styled.nav`
  width: max(150px, 10%);
  color: white;
  background: radial-gradient(
    circle at 50% 20%,
    rgb(43 62 133) 5%,
    rgb(20 34 85) 90%
  );
  height: 1000px;
  box-shadow: 5px 0 5px -3px rgba(0, 0, 0, 0.16),
    5px 0 5px -3px rgba(0, 0, 0, 0.12);
`;

export const StyledTitle = styled.h4`
  text-align: center;
`;

const StyledItem = styled.h5`
  margin: 10px 10px 10px;
  color: white;

  :hover {
    cursor: pointer;
  }
`;

export const StyledContent = styled.article`
  width: 100%;
`;

export default function Monopager({ page_title, items }: MonopagerArguments) {
  const [openedItem, setOpenedItem] = useState<MonopagerItemData | undefined>(
    undefined
  );

  return (
    <StyledContainer>
      <StyledNav>
        <StyledTitle>{page_title}</StyledTitle>
        <StyledRuler />
        {items.map((item) => (
          <StyledItem key={item.title} onClick={() => setOpenedItem(item)}>
            {item.title}
          </StyledItem>
        ))}
      </StyledNav>
      <StyledContent>
        {items.map((item) => (
          <MonopagerItem
            key={item.title}
            open={openedItem === item}
            toggleOpen={() => {
              openedItem === item
                ? setOpenedItem(undefined)
                : setOpenedItem(item);
            }}
            data={item}
          />
        ))}
      </StyledContent>
    </StyledContainer>
  );
}
