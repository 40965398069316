import React from "react";
import styled, { keyframes } from "styled-components";
import Header from "../../components/header/Header";
import Showcase from "../../components/showcase/Showcase";
import UseCaseCard from "../../components/use_case_card/UseCaseCard";
import CancerGrowth from "../use_cases/cancer_growth/CancerGrowth";
import CFD from "../use_cases/cfd/CFD";
import Chemistry from "../use_cases/chemistry/Chemistry";
import Climate from "../use_cases/climate/Climate";
import Combustion from "../use_cases/combustion/Combustion";
import Electrodynamics from "../use_cases/electrodynamics/Electrodynamics";
import Finance from "../use_cases/finance/Finance";
import HeatTransfer from "../use_cases/heat_transfer/HeatTransfer";
import MaterialDeformation from "../use_cases/material_deformation/MaterialDeformation";
import Robotics from "../use_cases/robotics/Robotics";
import SAT from "../use_cases/sat/SAT";
import SupplyChain from "../use_cases/supply_chain/SupplyChain";

export const use_cases: {
  [details: string]: {
    image_format: string;
    page_title: string;
    element: () => JSX.Element;
  };
} = {
  "Material Deformation": {
    image_format: "gif",
    page_title: "Material Deformation Simulator",
    element: MaterialDeformation,
  },
  "Computational fluid-dynamics": {
    image_format: "png",
    page_title: "CFD Simulator",
    element: CFD,
  },
  Combustion: {
    image_format: "png",
    page_title: "Combustion Simulator",
    element: Combustion,
  },
  "Heat transfer": {
    image_format: "png",
    page_title: "Heat transfer Simulator",
    element: HeatTransfer,
  },
  Chemistry: {
    image_format: "png",
    page_title: "Chemistry Simulator",
    element: Chemistry,
  },
  Finance: {
    image_format: "jpeg",
    page_title: "Finance Simulator",
    element: Finance,
  },
  Robotics: {
    image_format: "png",
    page_title: "Robotics",
    element: Robotics,
  },
  "Cancer growth": {
    image_format: "gif",
    page_title: "Cancer Growth Simulator",
    element: CancerGrowth,
  },
  "Supply Chain": {
    image_format: "png",
    page_title: "Supply Chain Optimizer",
    element: SupplyChain,
  },
  Climate: {
    image_format: "gif",
    page_title: "Climate Simulator",
    element: Climate,
  },
  Electrodynamics: {
    image_format: "jpg",
    page_title: "Electrodynamics Simulator",
    element: Electrodynamics,
  },
  SAT: {
    image_format: "png",
    page_title: "SAT Solver",
    element: SAT,
  },
};

const use_case_cards: {
  [details: string]: { card: JSX.Element; page_title: string };
} = {};
for (let use_case in use_cases) {
  let image_path =
    "/images/cards/" +
    use_case.toLowerCase().replace(" ", "_") +
    "." +
    use_cases[use_case].image_format;
  use_case_cards[use_case] = {
    card: UseCaseCard({
      title: use_case,
      image_source: image_path,
    }),
    page_title: use_cases[use_case].page_title,
  };
}

const disclaimerAnimation = keyframes`
    from{opacity: 0} to{opacity: 1}
`;

const StyledDisclaimer = styled.footer`
  position: absolute;
  height: 7px;
  font-size: 7px;
  bottom: 7px;
  margin: 0;
  right: 7px;
  padding: 0;
  animation-name: ${disclaimerAnimation};
  animation-duration: 0.8s;
`;

function Landing() {
  return (
    <React.Fragment>
      <Header />
      <Showcase
        use_case_cards={Object.keys(use_case_cards).map((uc) => (
          <div key={uc}>{use_case_cards[uc].card}</div>
        ))}
      />
      {false && (
        <StyledDisclaimer>
          These images are for illustration purpose only. They do not represent
          actual products, but target products under development.
        </StyledDisclaimer>
      )}
    </React.Fragment>
  );
}

export default Landing;
