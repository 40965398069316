import { useState } from "react";
import styled from "styled-components";

export type SelectorArguments<T> = {
  currentValue: T;
  setCurrentValue: (value: T) => void;
  availableValues: T[];
  defaultValue?: T;
  removeDefault?: boolean;
  name?: string;
  disabled?: boolean;
  showDefault?: boolean;
};

type StyledSelectArguments = {
  disabled: boolean;
};

const StyledSelect = styled.select`
  width: 12rem;
  height: 2rem;
  padding: 0rem 0.5rem;
  font-size: 1rem;
  color: rgb(0, 0, 0);
  background: #f9f9f9;
  border: 1px solid rgb(0, 174, 255);
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  cursor: ${({ disabled }: StyledSelectArguments) =>
    disabled ? "not-allowed" : "pointer"};

  &:hover {
    border-color: rgb(0, 174, 255);
    box-shadow: 3px 3px 6px rgb(0, 174, 255);
  }

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  }

  &:disabled {
    background: #f0f0f0;
    color: rgb(0, 174, 255);
    cursor: not-allowed;
    border-color: rgb(0, 174, 255);
    box-shadow: none;
  }
`;

const StyledOption = styled.option`
  background-color: rgb(255, 255, 255);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #007bff;
    color: rgb(255, 255, 255);
  }
`;

/**
 *
 * @param props
 *
 */
export default function Selector({
  currentValue,
  setCurrentValue,
  availableValues,
  defaultValue,
  removeDefault,
  name,
  disabled = false,
  showDefault = true,
}: SelectorArguments<string>) {
  const [valueChanged, setValueChanged] = useState(false);

  return (
    <StyledSelect
      id={name}
      name={name}
      value={currentValue}
      onChange={(e) => {
        setValueChanged(true);
        setCurrentValue(e.target.value);
      }}
      className="jh-categories-select"
      disabled={disabled}
    >
      {!(removeDefault && valueChanged) && showDefault && (
        <StyledOption value="">
          {defaultValue ? defaultValue : "---"}
        </StyledOption>
      )}
      {availableValues.map((value) => (
        <StyledOption key={value} value={value}>
          {value}
        </StyledOption>
      ))}
    </StyledSelect>
  );
}
