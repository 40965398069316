import styled, { keyframes } from "styled-components";

const raiseApparition = keyframes`
   from{top:1.61em; opacity:0} to{top:0; opacity:1}
`;

const StyledGrid = styled.article`
  display: flex;
  flex-wrap: wrap;
  gap: 2em 4em;
  margin: 2em 2em;
  position: relative;
  animation: ${raiseApparition} 0.8s;
  top: 5%;
  height: calc(100vh - 10rem);
  justify-content: space-evenly;
`;

function Showcase({ use_case_cards }: { use_case_cards: JSX.Element[] }) {
  return <StyledGrid>{use_case_cards}</StyledGrid>;
}

export default Showcase;
