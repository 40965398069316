import ProgressBar from "@ramonak/react-progress-bar";
import { useState } from "react";
import { GeneralApi } from "typescript-axios";

const generalApi = new GeneralApi();

function MaterialDeformation({
  jobId,
  callback,
}: {
  jobId: string;
  callback: () => void;
}) {
  const [progress, setProgress] = useState(0);

  let progressInterval: NodeJS.Timer;
  if (jobId !== "")
    progressInterval = setInterval(() => refreshProgress(), 2000);

  let idToken: any;
  for (const [key, value] of Object.entries(localStorage)) {
    if (key?.includes("idToken")) {
      idToken = value;
    }
  }

  function refreshProgress() {
    generalApi.getJob(jobId, idToken).then((res) => {
      const localProgress = res.data.progress;
      setProgress(localProgress);
      if (localProgress === 100) {
        clearInterval(progressInterval);
        setTimeout(() => setProgress(0), 1000);
        callback();
      }
    });
  }

  return progress === 0 ? (
    <></>
  ) : (
    <ProgressBar completed={progress} width="120px" />
  );
}

export default MaterialDeformation;
