"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * quick-md-api
 * The API for the material deformation use case of the Quick project
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialDeformationApi = exports.MaterialDeformationApiFactory = exports.MaterialDeformationApiFp = exports.MaterialDeformationApiAxiosParamCreator = exports.GeneralApi = exports.GeneralApiFactory = exports.GeneralApiFp = exports.GeneralApiAxiosParamCreator = exports.FlameApi = exports.FlameApiFactory = exports.FlameApiFp = exports.FlameApiAxiosParamCreator = exports.CFDApi = exports.CFDApiFactory = exports.CFDApiFp = exports.CFDApiAxiosParamCreator = exports.JobType = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.JobType = {
    Md: 'md',
    Combustion: 'combustion',
    Cfd: 'cfd'
};
/**
 * CFDApi - axios parameter creator
 * @export
 */
var CFDApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Returns the result of the run
         * @param {string} jobId Identifier of the job being inspected
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCFDResults: function (jobId, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'jobId' is not null or undefined
                            (0, common_1.assertParamExists)('getCFDResults', 'jobId', jobId);
                            localVarPath = "/cfd/results/{job-id}"
                                .replace("{".concat("job-id", "}"), encodeURIComponent(String(jobId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Starts the CFD simulation for isothermal Euler equations with time-dependent damping.
         * @param {string} [authorization]
         * @param {boolean} [precomputed] Indicator wether the job should ignore the input parameters and instead return a precomputed result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solveCFD: function (authorization, precomputed, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/cfd/solve";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (precomputed !== undefined) {
                                localVarQueryParameter['precomputed'] = precomputed;
                            }
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.CFDApiAxiosParamCreator = CFDApiAxiosParamCreator;
/**
 * CFDApi - functional programming interface
 * @export
 */
var CFDApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.CFDApiAxiosParamCreator)(configuration);
    return {
        /**
         * Returns the result of the run
         * @param {string} jobId Identifier of the job being inspected
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCFDResults: function (jobId, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCFDResults(jobId, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Starts the CFD simulation for isothermal Euler equations with time-dependent damping.
         * @param {string} [authorization]
         * @param {boolean} [precomputed] Indicator wether the job should ignore the input parameters and instead return a precomputed result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solveCFD: function (authorization, precomputed, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.solveCFD(authorization, precomputed, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.CFDApiFp = CFDApiFp;
/**
 * CFDApi - factory interface
 * @export
 */
var CFDApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.CFDApiFp)(configuration);
    return {
        /**
         * Returns the result of the run
         * @param {string} jobId Identifier of the job being inspected
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCFDResults: function (jobId, authorization, options) {
            return localVarFp.getCFDResults(jobId, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Starts the CFD simulation for isothermal Euler equations with time-dependent damping.
         * @param {string} [authorization]
         * @param {boolean} [precomputed] Indicator wether the job should ignore the input parameters and instead return a precomputed result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solveCFD: function (authorization, precomputed, options) {
            return localVarFp.solveCFD(authorization, precomputed, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.CFDApiFactory = CFDApiFactory;
/**
 * CFDApi - object-oriented interface
 * @export
 * @class CFDApi
 * @extends {BaseAPI}
 */
var CFDApi = /** @class */ (function (_super) {
    __extends(CFDApi, _super);
    function CFDApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Returns the result of the run
     * @param {string} jobId Identifier of the job being inspected
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CFDApi
     */
    CFDApi.prototype.getCFDResults = function (jobId, authorization, options) {
        var _this = this;
        return (0, exports.CFDApiFp)(this.configuration).getCFDResults(jobId, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Starts the CFD simulation for isothermal Euler equations with time-dependent damping.
     * @param {string} [authorization]
     * @param {boolean} [precomputed] Indicator wether the job should ignore the input parameters and instead return a precomputed result.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CFDApi
     */
    CFDApi.prototype.solveCFD = function (authorization, precomputed, options) {
        var _this = this;
        return (0, exports.CFDApiFp)(this.configuration).solveCFD(authorization, precomputed, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CFDApi;
}(base_1.BaseAPI));
exports.CFDApi = CFDApi;
/**
 * FlameApi - axios parameter creator
 * @export
 */
var FlameApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Returns the result of the run
         * @param {string} jobId Identifier of the job being inspected
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlameResults: function (jobId, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'jobId' is not null or undefined
                            (0, common_1.assertParamExists)('getFlameResults', 'jobId', jobId);
                            localVarPath = "/flame/results/{job-id}"
                                .replace("{".concat("job-id", "}"), encodeURIComponent(String(jobId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Starts the Flame simulation.
         * @param {string} [authorization]
         * @param {boolean} [precomputed] Indicator wether the job should ignore the input parameters and instead return a precomputed result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solveFlame: function (authorization, precomputed, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/flame/solve";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (precomputed !== undefined) {
                                localVarQueryParameter['precomputed'] = precomputed;
                            }
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.FlameApiAxiosParamCreator = FlameApiAxiosParamCreator;
/**
 * FlameApi - functional programming interface
 * @export
 */
var FlameApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.FlameApiAxiosParamCreator)(configuration);
    return {
        /**
         * Returns the result of the run
         * @param {string} jobId Identifier of the job being inspected
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlameResults: function (jobId, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getFlameResults(jobId, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Starts the Flame simulation.
         * @param {string} [authorization]
         * @param {boolean} [precomputed] Indicator wether the job should ignore the input parameters and instead return a precomputed result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solveFlame: function (authorization, precomputed, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.solveFlame(authorization, precomputed, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.FlameApiFp = FlameApiFp;
/**
 * FlameApi - factory interface
 * @export
 */
var FlameApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.FlameApiFp)(configuration);
    return {
        /**
         * Returns the result of the run
         * @param {string} jobId Identifier of the job being inspected
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlameResults: function (jobId, authorization, options) {
            return localVarFp.getFlameResults(jobId, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Starts the Flame simulation.
         * @param {string} [authorization]
         * @param {boolean} [precomputed] Indicator wether the job should ignore the input parameters and instead return a precomputed result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solveFlame: function (authorization, precomputed, options) {
            return localVarFp.solveFlame(authorization, precomputed, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.FlameApiFactory = FlameApiFactory;
/**
 * FlameApi - object-oriented interface
 * @export
 * @class FlameApi
 * @extends {BaseAPI}
 */
var FlameApi = /** @class */ (function (_super) {
    __extends(FlameApi, _super);
    function FlameApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Returns the result of the run
     * @param {string} jobId Identifier of the job being inspected
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlameApi
     */
    FlameApi.prototype.getFlameResults = function (jobId, authorization, options) {
        var _this = this;
        return (0, exports.FlameApiFp)(this.configuration).getFlameResults(jobId, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Starts the Flame simulation.
     * @param {string} [authorization]
     * @param {boolean} [precomputed] Indicator wether the job should ignore the input parameters and instead return a precomputed result.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlameApi
     */
    FlameApi.prototype.solveFlame = function (authorization, precomputed, options) {
        var _this = this;
        return (0, exports.FlameApiFp)(this.configuration).solveFlame(authorization, precomputed, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return FlameApi;
}(base_1.BaseAPI));
exports.FlameApi = FlameApi;
/**
 * GeneralApi - axios parameter creator
 * @export
 */
var GeneralApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * cancels a job
         * @param {string} jobId Identifier of the job being canceled
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelJob: function (jobId, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'jobId' is not null or undefined
                            (0, common_1.assertParamExists)('cancelJob', 'jobId', jobId);
                            localVarPath = "/job/cancel/{job-id}"
                                .replace("{".concat("job-id", "}"), encodeURIComponent(String(jobId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Returns the data of a job
         * @param {string} jobId Identifier of the job being requested
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJob: function (jobId, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'jobId' is not null or undefined
                            (0, common_1.assertParamExists)('getJob', 'jobId', jobId);
                            localVarPath = "/job/{job-id}"
                                .replace("{".concat("job-id", "}"), encodeURIComponent(String(jobId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Returns the parameters used to launch the job
         * @param {string} jobId Identifier of the job being requested
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobProject: function (jobId, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'jobId' is not null or undefined
                            (0, common_1.assertParamExists)('getJobProject', 'jobId', jobId);
                            localVarPath = "/job/{job-id}/project"
                                .replace("{".concat("job-id", "}"), encodeURIComponent(String(jobId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * returns the list of jobs belonging to the connected user
         * @param {Array<JobType>} [jobTypes] use case used to filter jobs
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobs: function (jobTypes, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/jobs";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (jobTypes) {
                                localVarQueryParameter['job-types'] = jobTypes;
                            }
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.GeneralApiAxiosParamCreator = GeneralApiAxiosParamCreator;
/**
 * GeneralApi - functional programming interface
 * @export
 */
var GeneralApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.GeneralApiAxiosParamCreator)(configuration);
    return {
        /**
         * cancels a job
         * @param {string} jobId Identifier of the job being canceled
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelJob: function (jobId, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cancelJob(jobId, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Returns the data of a job
         * @param {string} jobId Identifier of the job being requested
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJob: function (jobId, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getJob(jobId, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Returns the parameters used to launch the job
         * @param {string} jobId Identifier of the job being requested
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobProject: function (jobId, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getJobProject(jobId, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * returns the list of jobs belonging to the connected user
         * @param {Array<JobType>} [jobTypes] use case used to filter jobs
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobs: function (jobTypes, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getJobs(jobTypes, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.GeneralApiFp = GeneralApiFp;
/**
 * GeneralApi - factory interface
 * @export
 */
var GeneralApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.GeneralApiFp)(configuration);
    return {
        /**
         * cancels a job
         * @param {string} jobId Identifier of the job being canceled
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelJob: function (jobId, authorization, options) {
            return localVarFp.cancelJob(jobId, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Returns the data of a job
         * @param {string} jobId Identifier of the job being requested
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJob: function (jobId, authorization, options) {
            return localVarFp.getJob(jobId, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Returns the parameters used to launch the job
         * @param {string} jobId Identifier of the job being requested
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobProject: function (jobId, authorization, options) {
            return localVarFp.getJobProject(jobId, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * returns the list of jobs belonging to the connected user
         * @param {Array<JobType>} [jobTypes] use case used to filter jobs
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobs: function (jobTypes, authorization, options) {
            return localVarFp.getJobs(jobTypes, authorization, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.GeneralApiFactory = GeneralApiFactory;
/**
 * GeneralApi - object-oriented interface
 * @export
 * @class GeneralApi
 * @extends {BaseAPI}
 */
var GeneralApi = /** @class */ (function (_super) {
    __extends(GeneralApi, _super);
    function GeneralApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * cancels a job
     * @param {string} jobId Identifier of the job being canceled
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.cancelJob = function (jobId, authorization, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).cancelJob(jobId, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Returns the data of a job
     * @param {string} jobId Identifier of the job being requested
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.getJob = function (jobId, authorization, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).getJob(jobId, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Returns the parameters used to launch the job
     * @param {string} jobId Identifier of the job being requested
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.getJobProject = function (jobId, authorization, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).getJobProject(jobId, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * returns the list of jobs belonging to the connected user
     * @param {Array<JobType>} [jobTypes] use case used to filter jobs
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.getJobs = function (jobTypes, authorization, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).getJobs(jobTypes, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return GeneralApi;
}(base_1.BaseAPI));
exports.GeneralApi = GeneralApi;
/**
 * MaterialDeformationApi - axios parameter creator
 * @export
 */
var MaterialDeformationApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * deletes a project
         * @param {string} projectId Identifier of the project being loaded
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: function (projectId, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'projectId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteProject', 'projectId', projectId);
                            localVarPath = "/md/project/{project-id}"
                                .replace("{".concat("project-id", "}"), encodeURIComponent(String(projectId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * returns all algorithms effective on this problem
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlgorithms: function (authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/md/algorithms";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * returns all available material options
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaterials: function (authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/md/materials";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Returns the result of the run
         * @param {string} jobId Identifier of the job being inspected
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMdResults: function (jobId, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'jobId' is not null or undefined
                            (0, common_1.assertParamExists)('getMdResults', 'jobId', jobId);
                            localVarPath = "/md/results/{job-id}"
                                .replace("{".concat("job-id", "}"), encodeURIComponent(String(jobId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * returns an unattributed id for a new project
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewProjectId: function (authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/md/project/new-id";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * returns all noise models which may disturb the execution of the algorithm
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoiseModels: function (authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/md/noise-models";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * returns all the options for the material deformation simulation
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptions: function (authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/md/options";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * returns the parameters of a project
         * @param {string} projectId Identifier of the project being loaded
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: function (projectId, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'projectId' is not null or undefined
                            (0, common_1.assertParamExists)('getProject', 'projectId', projectId);
                            localVarPath = "/md/project/{project-id}"
                                .replace("{".concat("project-id", "}"), encodeURIComponent(String(projectId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * returns the list of projects saved on the cloud
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: function (authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/md/projects";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * returns all available shapes
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShapes: function (authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/md/shapes";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * saves the parameters of a project
         * @param {string} projectId Identifier of the project being loaded
         * @param {Project} project The body should contain the parameters of the project
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProject: function (projectId, project, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'projectId' is not null or undefined
                            (0, common_1.assertParamExists)('saveProject', 'projectId', projectId);
                            // verify required parameter 'project' is not null or undefined
                            (0, common_1.assertParamExists)('saveProject', 'project', project);
                            localVarPath = "/md/project/{project-id}"
                                .replace("{".concat("project-id", "}"), encodeURIComponent(String(projectId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(project, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Starts the material deformation simulation run with the inputs given in the body
         * @param {MdSolveData} mdSolveData The body should contain the data of the run as a json
         * @param {string} [authorization]
         * @param {boolean} [precomputed] Indicator wether the job should ignore the input parameters and instead return a precomputed result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solveMd: function (mdSolveData, authorization, precomputed, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'mdSolveData' is not null or undefined
                            (0, common_1.assertParamExists)('solveMd', 'mdSolveData', mdSolveData);
                            localVarPath = "/md/solve";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication colibriOAuth required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication colibriOAuth required
                            _a.sent();
                            if (precomputed !== undefined) {
                                localVarQueryParameter['precomputed'] = precomputed;
                            }
                            if (authorization != null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(mdSolveData, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.MaterialDeformationApiAxiosParamCreator = MaterialDeformationApiAxiosParamCreator;
/**
 * MaterialDeformationApi - functional programming interface
 * @export
 */
var MaterialDeformationApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.MaterialDeformationApiAxiosParamCreator)(configuration);
    return {
        /**
         * deletes a project
         * @param {string} projectId Identifier of the project being loaded
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: function (projectId, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteProject(projectId, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * returns all algorithms effective on this problem
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlgorithms: function (authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAlgorithms(authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * returns all available material options
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaterials: function (authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMaterials(authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Returns the result of the run
         * @param {string} jobId Identifier of the job being inspected
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMdResults: function (jobId, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMdResults(jobId, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * returns an unattributed id for a new project
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewProjectId: function (authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getNewProjectId(authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * returns all noise models which may disturb the execution of the algorithm
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoiseModels: function (authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getNoiseModels(authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * returns all the options for the material deformation simulation
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptions: function (authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getOptions(authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * returns the parameters of a project
         * @param {string} projectId Identifier of the project being loaded
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: function (projectId, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getProject(projectId, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * returns the list of projects saved on the cloud
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: function (authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getProjects(authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * returns all available shapes
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShapes: function (authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getShapes(authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * saves the parameters of a project
         * @param {string} projectId Identifier of the project being loaded
         * @param {Project} project The body should contain the parameters of the project
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProject: function (projectId, project, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.saveProject(projectId, project, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Starts the material deformation simulation run with the inputs given in the body
         * @param {MdSolveData} mdSolveData The body should contain the data of the run as a json
         * @param {string} [authorization]
         * @param {boolean} [precomputed] Indicator wether the job should ignore the input parameters and instead return a precomputed result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solveMd: function (mdSolveData, authorization, precomputed, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.solveMd(mdSolveData, authorization, precomputed, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.MaterialDeformationApiFp = MaterialDeformationApiFp;
/**
 * MaterialDeformationApi - factory interface
 * @export
 */
var MaterialDeformationApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.MaterialDeformationApiFp)(configuration);
    return {
        /**
         * deletes a project
         * @param {string} projectId Identifier of the project being loaded
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: function (projectId, authorization, options) {
            return localVarFp.deleteProject(projectId, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * returns all algorithms effective on this problem
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlgorithms: function (authorization, options) {
            return localVarFp.getAlgorithms(authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * returns all available material options
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaterials: function (authorization, options) {
            return localVarFp.getMaterials(authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Returns the result of the run
         * @param {string} jobId Identifier of the job being inspected
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMdResults: function (jobId, authorization, options) {
            return localVarFp.getMdResults(jobId, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * returns an unattributed id for a new project
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewProjectId: function (authorization, options) {
            return localVarFp.getNewProjectId(authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * returns all noise models which may disturb the execution of the algorithm
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoiseModels: function (authorization, options) {
            return localVarFp.getNoiseModels(authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * returns all the options for the material deformation simulation
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptions: function (authorization, options) {
            return localVarFp.getOptions(authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * returns the parameters of a project
         * @param {string} projectId Identifier of the project being loaded
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: function (projectId, authorization, options) {
            return localVarFp.getProject(projectId, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * returns the list of projects saved on the cloud
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: function (authorization, options) {
            return localVarFp.getProjects(authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * returns all available shapes
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShapes: function (authorization, options) {
            return localVarFp.getShapes(authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * saves the parameters of a project
         * @param {string} projectId Identifier of the project being loaded
         * @param {Project} project The body should contain the parameters of the project
         * @param {string} [authorization]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProject: function (projectId, project, authorization, options) {
            return localVarFp.saveProject(projectId, project, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Starts the material deformation simulation run with the inputs given in the body
         * @param {MdSolveData} mdSolveData The body should contain the data of the run as a json
         * @param {string} [authorization]
         * @param {boolean} [precomputed] Indicator wether the job should ignore the input parameters and instead return a precomputed result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solveMd: function (mdSolveData, authorization, precomputed, options) {
            return localVarFp.solveMd(mdSolveData, authorization, precomputed, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.MaterialDeformationApiFactory = MaterialDeformationApiFactory;
/**
 * MaterialDeformationApi - object-oriented interface
 * @export
 * @class MaterialDeformationApi
 * @extends {BaseAPI}
 */
var MaterialDeformationApi = /** @class */ (function (_super) {
    __extends(MaterialDeformationApi, _super);
    function MaterialDeformationApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * deletes a project
     * @param {string} projectId Identifier of the project being loaded
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialDeformationApi
     */
    MaterialDeformationApi.prototype.deleteProject = function (projectId, authorization, options) {
        var _this = this;
        return (0, exports.MaterialDeformationApiFp)(this.configuration).deleteProject(projectId, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * returns all algorithms effective on this problem
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialDeformationApi
     */
    MaterialDeformationApi.prototype.getAlgorithms = function (authorization, options) {
        var _this = this;
        return (0, exports.MaterialDeformationApiFp)(this.configuration).getAlgorithms(authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * returns all available material options
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialDeformationApi
     */
    MaterialDeformationApi.prototype.getMaterials = function (authorization, options) {
        var _this = this;
        return (0, exports.MaterialDeformationApiFp)(this.configuration).getMaterials(authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Returns the result of the run
     * @param {string} jobId Identifier of the job being inspected
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialDeformationApi
     */
    MaterialDeformationApi.prototype.getMdResults = function (jobId, authorization, options) {
        var _this = this;
        return (0, exports.MaterialDeformationApiFp)(this.configuration).getMdResults(jobId, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * returns an unattributed id for a new project
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialDeformationApi
     */
    MaterialDeformationApi.prototype.getNewProjectId = function (authorization, options) {
        var _this = this;
        return (0, exports.MaterialDeformationApiFp)(this.configuration).getNewProjectId(authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * returns all noise models which may disturb the execution of the algorithm
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialDeformationApi
     */
    MaterialDeformationApi.prototype.getNoiseModels = function (authorization, options) {
        var _this = this;
        return (0, exports.MaterialDeformationApiFp)(this.configuration).getNoiseModels(authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * returns all the options for the material deformation simulation
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialDeformationApi
     */
    MaterialDeformationApi.prototype.getOptions = function (authorization, options) {
        var _this = this;
        return (0, exports.MaterialDeformationApiFp)(this.configuration).getOptions(authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * returns the parameters of a project
     * @param {string} projectId Identifier of the project being loaded
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialDeformationApi
     */
    MaterialDeformationApi.prototype.getProject = function (projectId, authorization, options) {
        var _this = this;
        return (0, exports.MaterialDeformationApiFp)(this.configuration).getProject(projectId, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * returns the list of projects saved on the cloud
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialDeformationApi
     */
    MaterialDeformationApi.prototype.getProjects = function (authorization, options) {
        var _this = this;
        return (0, exports.MaterialDeformationApiFp)(this.configuration).getProjects(authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * returns all available shapes
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialDeformationApi
     */
    MaterialDeformationApi.prototype.getShapes = function (authorization, options) {
        var _this = this;
        return (0, exports.MaterialDeformationApiFp)(this.configuration).getShapes(authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * saves the parameters of a project
     * @param {string} projectId Identifier of the project being loaded
     * @param {Project} project The body should contain the parameters of the project
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialDeformationApi
     */
    MaterialDeformationApi.prototype.saveProject = function (projectId, project, authorization, options) {
        var _this = this;
        return (0, exports.MaterialDeformationApiFp)(this.configuration).saveProject(projectId, project, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Starts the material deformation simulation run with the inputs given in the body
     * @param {MdSolveData} mdSolveData The body should contain the data of the run as a json
     * @param {string} [authorization]
     * @param {boolean} [precomputed] Indicator wether the job should ignore the input parameters and instead return a precomputed result.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialDeformationApi
     */
    MaterialDeformationApi.prototype.solveMd = function (mdSolveData, authorization, precomputed, options) {
        var _this = this;
        return (0, exports.MaterialDeformationApiFp)(this.configuration).solveMd(mdSolveData, authorization, precomputed, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return MaterialDeformationApi;
}(base_1.BaseAPI));
exports.MaterialDeformationApi = MaterialDeformationApi;
