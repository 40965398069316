import { useState } from "react";
import { BiUpload } from "react-icons/bi";
import "./UploadSection.css";

function UploadBox({ name }: { name: string }) {
  const [geometricFileName, setGeometricFileName] = useState("");

  const handleGeometricUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setGeometricFileName(file.name);
    }
  };

  return (
    <div className="upload-box" style={{ padding: "1em" }}>
      <label htmlFor="geometric-profile" className="upload-label">
        <div className="upload-icon" onClick={() => console.log("clicked")}>
          <BiUpload />
        </div>
        {name}
      </label>
      <input
        type="file"
        id="geometric-profile"
        className="upload-input"
        style={{ display: "none" }}
        onChange={handleGeometricUpload}
      />
      {geometricFileName && <p className="file-name">{geometricFileName}</p>}
    </div>
  );
}

export default UploadBox;
