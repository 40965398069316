import { MathJax } from "better-react-mathjax";
import styled from "styled-components";
import FloatInput from "../../../components/FloatInput";

type InputArgument = {
  disabled?: boolean;
};

export const CustomMaterial = styled.div`
  display: ${({ visible }: { visible: boolean }) =>
    visible ? "block" : "none"};
`;

export const Input = styled.input`
  box-shadow: 0.1rem 0.1rem black;
  border-radius: 0.25rem;
  padding: 0.1rem;
  font-size: 0.9rem;
  max-width: 70%;
  cursor: ${({ disabled = false }: InputArgument) =>
    disabled ? "not-allowed" : "default"};
`;

export const FInput = styled(FloatInput)`
  box-shadow: 0.1rem 0.1rem black;
  border-radius: 0.25rem;
  padding: 0.1rem;
  font-size: 0.9rem;
  max-width: 70%;
  cursor: ${({ disabled = false }: InputArgument) =>
    disabled ? "not-allowed" : "default"};
`;

export const SideInput = styled.div`
  margin: 0.5rem;

  input,
  select {
    margin: 0.5rem;
  }
`;

export const MultipleParameterDiv = styled.div`
  border: 2.5px solid #5f5f5f;
`;

export const Header = styled.h3`
  filter: drop-shadow(1px 1px 1px black);
  font-weight: bold;
  font-size: 1.4rem;
  padding: 1rem 0 0 0;
  border-color: white;
  border-style: solid;
  border-width: 1px 0 0 0;
`;

export const SubHeader = styled(Header)`
  border-width: 0;
  margin: 0rem 1rem 1rem 1rem;
`;

export const Label = styled.label`
  filter: drop-shadow(0.5px 1px 1px black);
  font-weight: "400";
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100vh - 5.5rem);
`;

export const Form = styled.form`
  text-align: left;
`;

export const Math = styled(MathJax)`
  font-size: 1em;
  margin: 0.5em;
  color: black;
`;

export const MathWhite = styled(MathJax)`
  font-size: 1em;
  margin: 0.5em;
  color: white;
`;

export const Side = styled.div`
  flex: 0.1;
  filter: drop-shadow(1px 1px 1px black);
  color: white;
  background: radial-gradient(
    circle at 50% 20%,
    rgb(43 62 133) 5%,
    rgb(20 34 85) 90%
  );
  padding: 0.5rem;
  text-align: center;
  position: relative;
`;

export const Equations = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Formulas = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline-end: 5em;
`;

export const Functions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Center = styled.div`
  flex: 0.8;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  gap: 1em;
`;

export const ResultImage = styled.img`
  max-width: 100%;
  margin: auto;
  padding: 1em;

  @media (min-width: 1080px) {
    max-width: 60%;
  }
`;

export const Results = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6em;
`;

export const ResultPane = styled.div`
  display: flex;
  flex-direction: column;
  color: black;
`;

export const Graphs = styled(ResultPane)`
  gap: 1em;
`;

export const ParamInput = styled.div`
  display: flex;
`;

export const UseCase = styled.h3`
  filter: drop-shadow(1px 1px 1px black);
  font-weight: bold;
  font-size: 1.5rem;
  border-color: white;
  border-style: solid;
  border-width: 0 0 1px 0;
  padding: 0 0 1rem 0;
`;
