import { Data } from "plotly.js";
import Plot from "react-plotly.js";

export function SimplePlot2D({ data, name }: { data: number[]; name: string }) {
  var layout = {
    title: name,
    autosize: false,
    width: 500,
    height: 500,
    margin: {
      l: 65,
      r: 50,
      b: 65,
      t: 90,
    },
  };
  return (
    <Plot
      data={[
        {
          y: data,
          type: "scatter",
        },
      ]}
      layout={layout}
    />
  );
}

export function Plot2D({
  data,
  name,
  curve_names,
}: {
  data: number[][];
  name: string;
  curve_names: string[];
}) {
  let xs = data.map((line) => line[0]);
  let fs: Data[] = [];
  for (let i = 1; i < data[0].length; i++) {
    fs.push({
      x: xs,
      y: data.map((line) => line[i]),
      type: "scatter",
      name: curve_names[i - 1],
    });
  }
  var layout = {
    title: name,
    autosize: false,
    width: 500,
    height: 500,
    margin: {
      l: 65,
      r: 50,
      b: 65,
      t: 90,
    },
  };
  return <Plot data={fs} layout={layout} />;
}

export function Plot3D({
  data,
  name,
  axes,
}: {
  data: number[][];
  name: string;
  axes: { x: string; y: string; z: string };
}) {
  var layout = {
    title: name,
    autosize: false,
    width: 500,
    height: 500,
    margin: {
      l: 65,
      r: 50,
      b: 65,
      t: 90,
    },
    scene: {
      xaxis: { title: axes.x },
      yaxis: { title: axes.y },
      zaxis: { title: axes.z },
    },
  };

  return (
    <Plot
      data={[
        {
          z: data,
          type: "surface",
        },
      ]}
      layout={layout}
    />
  );
}
